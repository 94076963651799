
import * as React from 'react';

export default function FAQ() {
  const faqs = [
    {
      question: "What is your refund policy?",
      answer: "None of our goods or services are refundable. If you have any questions, please contact us before making a purchase.",
    },
    {
      question: "How do I schedule a tutoring lesson?",
      answer: "To schedule a Pay As You Go tutoring lesson, you simply need to click on one of the scheduling links above. You will be taken to a page where you can select a date and time that works for you, as well as put in your payment information.\nTo schedule Lesson Bundle tutoring lessons, you will need to purchase a bundle of lessons. Once you have purchased a bundle and schedule your first lesson, you will be provided with a link to schedule your remaining lessons at your convenience.\nTo schedule Consistent Schedule tutoring lessons, you will follow the same procedure as Pay As You Go lessons except you will choose the duration of weeks you want to have lessons for on your chosen timeslot.",
    },
    {
      question: "Why does my lesson need to be confirmed?",
      answer: "All lessons need to be confirmed before they are officially scheduled. This is to ensure that the tutor is available and that the lesson can be conducted at the scheduled time.",
    },
    {
      question: "When do lessons get charged?",
      answer: "Pay As You Go lessons are charged after the lesson has taken place.\nLesson Bundles are charged immediately on purchase.\nConsistent Schedule packages are charged on a monthly basis.",
    },
    {
      question: "How far out can I schedule a lesson?",
      answer: "With the exception of Consistent Schedule packaged lessons, lessons can be scheduled up to two weeks in advance. Consistent Schedule lessons are able to be scheduled up to 24 weeks in advance.",
    },
    {
      question: "How long do tutoring lessons last?",
      answer: "A typical lesson runs for 60 minutes. I offer additional 90 minute and 120 minute options when booking for those who need longer sessions. We do not offer lessons shorter than 60 minutes.",
    },
    {
      question: "How does cancellations/rescheduling work?",
      answer: "We must be notified of cancellations 24+ hours in advance to avoid being charged (excludes Consistent Schedule lessons). Same day rescheduling is allowed for all lesson types, as long as availability exists, and will incur a charge of $20. Rescheduling can only be done by contacting us directly.",
    },
  ];

  // Function to convert newlines to <br /> tags with additional space
  const formatAnswer = (text) => {
    // Split text by newline and trim any extra whitespace from the end
    const lines = text.split('\n').map(line => line.trim()).filter(line => line.length > 0);
    
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < lines.length - 1 && (
          <>
            <br />
            <br />
          </>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div style={{ backgroundColor: 'white'}}>
      <div style={{ maxWidth: '1280px', margin: '0 auto', paddingLeft: '24px', paddingRight: '24px' }}>
        {/* <div style={{ maxWidth: '640px', margin: '0 auto', textAlign: 'center' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>
            Frequently Asked Questions
          </h2>
          <p style={{ marginTop: '16px', fontSize: '18px', color: '#4b5563', lineHeight: '1.6' }}>
            Have questions? We've got answers. If you can't find what you're looking for, feel free to reach out to our support team.
          </p>
        </div> */}
        <div style={{ marginTop: '32px' }}>
          {faqs.map((faq, index) => (
            <div
              key={index}
              style={{
                borderRadius: '12px',
                backgroundColor: '#f9fafb',
                padding: '24px',
                marginBottom: '16px',
                borderWidth: '1px',
                borderColor: '#d1d5db',
              }}
            >
              <h3
                style={{
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#1f2937',
                  letterSpacing: '-0.015em',
                  marginBottom: '20px',
                }}
              >
                {faq.question}
              </h3>
              <p style={{ fontSize: '16px', color: '#4b5563', lineHeight: '1.6' }}>{formatAnswer(faq.answer)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}