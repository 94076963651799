import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// import Link from './link';
// import config from '../../config';
import { Sidebar, ListItem } from './styles/Sidebar';

const SidebarLayout = ({ location }) => {
  const [slug, setSlug] = React.useState();

  React.useEffect(() => {
    setSlug(location.pathname.replace('/course', '').replace(/\/$/, ''));
  }, [location.pathname]);

  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx {
            edges {
              node {
                fields {
                  slug
                }
                tableOfContents
              }
            }
          }
        }
      `}
      render={({ allMdx }) => {
        let finalNavItems;

        if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
          allMdx.edges.map((item) => {
            let innerItems;

            if (item !== undefined) {
              if (item.node.fields.slug === location.pathname || item.node.fields.slug === slug) {
                if (item.node.tableOfContents.items) {
                  innerItems = item.node.tableOfContents.items.map((innerItem, index) => {
                    const itemId = innerItem.title
                      ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                      : '#';

                    // Recursively process nested items
                    const processNestedItems = (items) => {
                      return items.map((nestedItem, nestedIndex) => {
                        const nestedItemId = nestedItem.title
                          ? nestedItem.title.replace(/\s+/g, '').toLowerCase()
                          : '#';
                        return (
                          <ListItem
                            key={nestedIndex}
                            to={`#${nestedItemId}`}
                            level={nestedItem.depth}
                          >
                            {nestedItem.title}
                            {nestedItem.items && processNestedItems(nestedItem.items)}
                          </ListItem>
                        );
                      });
                    };

                    return (
                      <ListItem key={index} to={`#${itemId}`} level={1}>
                        {innerItem.title}
                        {/* {innerItem.items[0].title} */}
                        {innerItem.items && processNestedItems(innerItem.items)}
                      </ListItem>
                    );
                  });
                }
              }
            }
            if (innerItems) {
              finalNavItems = innerItems;
            }
          });
        }

        if (finalNavItems && finalNavItems.length) {
          return (
            <Sidebar>
              <ul className={'rightSideBarUL'}>
                <li className={'rightSideTitle'}>CONTENTS</li>
                {finalNavItems}
              </ul>
            </Sidebar>
          );
        } else {
          return (
            <Sidebar>
              <ul></ul>
            </Sidebar>
          );
        }
      }}
    />
  );
};

export default SidebarLayout;
