import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCircleExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import './components.css'; // Import the CSS file

export const IncompleteSection = ({ children }) => {
    return (
        <div className="alert-danger">
            <div className="alert-danger-icon">
                <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="icon"
                />
            </div>
            <div className="alert-danger-content">
                <h3 className="alert-danger-title">This page is not complete.</h3>
                <div className="alert-danger-message">
                    <p>Quality content takes time. Check back in later.</p>
                    <ul>
                        {React.Children.map(children, (child, index) => (
                            <li key={index}>
                                <div className="alert-danger-list-item">
                                    {child}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
        <div className="coming-soon-content">
          <div className="coming-soon-icon">
            <FontAwesomeIcon
              icon={faClock}
              className="icon"
              />
          </div>
          <div className="coming-soon-text">
            <h3 className="coming-soon-title">Content Coming Soon!</h3>
            <div className="coming-soon-message">
              <p>We're working hard to bring you new content. Please check back later.</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export const Resources = ({ children }) => {
    return (
        <div className="resources-wrapper">
            <div className="resources-container">
                <div className="resources-content">
                    <table className="resources-table">
                        <thead>
                            <tr>
                                <th colSpan="5" className="resources-header">
                                    Resources
                                </th>
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export const Resource = ({ source, title, url, starred, children }) => {
    return (
        <tr className="resource-row">
            <td className="resource-icon-cell">
                <div className="resource-icon-container">
                    <span className={`resource-icon ${starred ? 'starred' : ''}`}></span>
                </div>
            </td>
            <td className="resource-source-cell">
                <span className="resource-source">{source}</span>
            </td>
            <td className="resource-title-cell">
                <div className="resource-title-container">
                    <svg className="resource-star-icon" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <a href={url} target="_blank" rel="nofollow noopener noreferrer" className="resource-title-link">
                        {title}
                    </a>
                </div>
            </td>
            <td className="resource-description-cell">
                <p>{children}</p>
            </td>
            <td className="resource-actions-cell">
                <button className="resource-actions-button">
                    <svg className="resource-actions-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                    </svg>
                </button>
            </td>
        </tr>
    );
};

export const Warning = ({ title, children }) => {
    return (
        <div className="alert-warning">
            <div className="alert-warning-icon">
                <i className="fas fa-exclamation-triangle"></i>
            </div>
            <div className="alert-warning-content">
                <h3 className="alert-warning-title">{title}</h3>
                <div className="alert-warning-message">
                    {children}
                </div>
            </div>
        </div>
    );
};

export const Important = ({ children }) => {
    return (
        <div className="important-container">
            <div className="important-icon">
                <FontAwesomeIcon icon={faCircleExclamation} />
            </div>
            <div className="important-content">
                {/* {title && <h3 className="important-title">{title}</h3>} */}
                <div className="important-message">
                    {children}
                </div>
            </div>
        </div>
    );
};