import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const includedFeatures = [
    'Private forum access',
    'Member resources',
    'Entry to annual conference',
    'Official member t-shirt',
  ];
  
  export default function Tutoring() {
    return (
      <div style={{ 
        backgroundColor: 'white', 
        // paddingTop: '24px', 
        // paddingBottom: '32px' 
        }}>
        <div style={{ maxWidth: '1280px', margin: '0 auto', paddingLeft: '24px', paddingRight: '24px' }}>
          {/* <div style={{ maxWidth: '640px', margin: '0 auto', textAlign: 'center' }}>
            <h2 style={{ fontSize: '36px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>Simple no-tricks pricing</h2>
            <p style={{ marginTop: '24px', fontSize: '18px', lineHeight: '1.75', color: '#4b5563' }}>
              Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas
              in. Explicabo id ut laborum.
            </p>
          </div> */}
          <div style={{ 
            // marginTop: '64px', 
            // margin: '64px auto 0', 
            borderRadius: '24px', 
            borderWidth: '1px', 
            borderColor: '#e5e7eb', 
            display: 'flex', 
            flexDirection: 'column', 
            lg: { marginLeft: '0', flexDirection: 'row', maxWidth: 'none' } 
            }}>
            {/* <div style={{ padding: '32px', flexGrow: 1 }}>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>Lifetime membership</h3>
              <p style={{ marginTop: '24px', fontSize: '16px', lineHeight: '1.75', color: '#4b5563' }}>
                Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis
                repellendus etur quidem assumenda.
              </p>
              <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center', gap: '16px' }}>
                <h4 style={{ fontSize: '14px', fontWeight: '600', color: '#6366f1', flexShrink: 0 }}>What’s included</h4>
                <div style={{ height: '1px', backgroundColor: '#f3f4f6', flexGrow: 1 }} />
              </div>
              <ul
                role="list"
                style={{ marginTop: '32px', display: 'grid', gridTemplateColumns: '1fr', gap: '16px', fontSize: '14px', lineHeight: '1.75', color: '#4b5563' }}
                className="sm:grid-cols-2"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} style={{ display: 'flex', gap: '12px' }}>
                    <FontAwesomeIcon icon={faCheck} aria-hidden="true" style={{ height: '24px', width: '20px', flexShrink: 0, color: '#6366f1' }} />
                    {feature}
                  </li>
                ))}
              </ul>
            </div> */}
            <div style={{ backgroundColor: 'white', paddingTop: '24px', paddingBottom: '32px' }}>
                <div style={{ maxWidth: '1280px', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                    <div
                        style={{
                        marginTop: '-8px',
                        padding: '8px',
                        width: '100%',
                        minWidth: '350px',
                        flexShrink: 0,
                        flexBasis: 'calc(45% - 16px)', // Allows divs to be side by side until they wrap
                        boxSizing: 'border-box',
                        }}
                    >
                        <div
                        style={{
                            borderRadius: '16px',
                            backgroundColor: '#f9fafb',
                            paddingTop: '40px',
                            paddingBottom: '40px',
                            textAlign: 'center',
                            borderWidth: '1px',
                            borderColor: '#d1d5db',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                        >
                        <div style={{ margin: '0 auto', paddingLeft: '32px', paddingRight: '32px' }}>
                            <p style={{ fontSize: '16px', fontWeight: '600', color: '#4b5563' }}>Pay As You Go</p>
                            {/* <p style={{ fontSize: '16px', fontWeight: '600', color: '#4b5563' }}>(Daytime Weekdays)</p> */}
                            <p style={{ display: 'block', fontSize: '16px', marginTop: '15px', fontWeight: '400', color: '#a7a7a7' }}>Book available daytime hours during the weekdays</p>
                            <p
                            style={{
                                marginTop: '24px',
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                            >
                            <span style={{ fontSize: '48px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>
                                $100
                            </span>
                            <span
                                style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#4b5563',
                                letterSpacing: '0.05em',
                                }}
                            >
                                /hour
                            </span>
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} No commitments -- pay for lessons one at a time
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Cheaper pricing than evening bookings
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Schedule up to 2 weeks in advance
                            </p>
                            <a
                            href="https://cal.com/calcprep/daytime"
                            style={{
                                marginTop: '40px',
                                display: 'block',
                                width: '100%',
                                borderRadius: '8px',
                                backgroundColor: '#6366f1',
                                padding: '8px 12px',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: 'white',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                textDecoration: 'none',
                            }}
                            >
                            Schedule Now
                            </a>
                            {/* <p style={{ marginTop: '24px', fontSize: '12px', lineHeight: '1.25', color: '#4b5563' }}>
                            Invoices and receipts available for easy company reimbursement
                            </p> */}
                        </div>
                        </div>
                    </div>

                    <div
                        style={{
                        marginTop: '-8px',
                        padding: '8px',
                        width: '100%',
                        minWidth: '350px',
                        flexShrink: 0,
                        flexBasis: 'calc(45% - 16px)', // Allows divs to be side by side until they wrap
                        boxSizing: 'border-box',
                        }}
                    >
                        <div
                        style={{
                            borderRadius: '16px',
                            backgroundColor: '#f9fafb',
                            paddingTop: '40px',
                            paddingBottom: '40px',
                            textAlign: 'center',
                            borderWidth: '1px',
                            borderColor: '#d1d5db',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                        >
                        <div style={{ margin: '0 auto', paddingLeft: '32px', paddingRight: '32px' }}>
                            <p style={{ fontSize: '16px', fontWeight: '600', color: '#4b5563' }}>Pay As You Go</p>
                            {/* <p style={{ fontSize: '16px', fontWeight: '600', color: '#4b5563' }}>(Anytime)</p> */}
                            <p style={{ display: 'block', fontSize: '16px', marginTop: '15px', fontWeight: '400', color: '#a7a7a7' }}>Book any available timeslots (daytime, evening, weekends)</p>
                            <p
                            style={{
                                marginTop: '24px',
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                            >
                            <span style={{ fontSize: '48px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>
                                $120
                            </span>
                            <span
                                style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#4b5563',
                                letterSpacing: '0.05em',
                                }}
                            >
                                /hour
                            </span>
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} No commitments -- pay for lessons one at a time
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} More timeslots available
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Schedule up to 2 weeks in advance
                            </p>
                            <a
                            href="https://cal.com/calcprep/anytime"
                            style={{
                                marginTop: '40px',
                                display: 'block',
                                width: '100%',
                                borderRadius: '8px',
                                backgroundColor: '#6366f1',
                                padding: '8px 12px',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: 'white',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                textDecoration: 'none',
                            }}
                            >
                            Schedule Now
                            </a>
                            {/* <p style={{ marginTop: '24px', fontSize: '12px', lineHeight: '1.25', color: '#4b5563' }}>
                            Invoices and receipts available for easy company reimbursement
                            </p> */}
                        </div>
                        </div>
                    </div>

                    <div
                        style={{
                        marginTop: '-8px',
                        padding: '8px',
                        width: '100%',
                        minWidth: '350px',
                        flexShrink: 0,
                        flexBasis: 'calc(45% - 16px)', // Allows divs to be side by side until they wrap
                        boxSizing: 'border-box',
                        }}
                    >
                        <div
                        style={{
                            borderRadius: '16px',
                            backgroundColor: '#f9fafb',
                            paddingTop: '40px',
                            paddingBottom: '40px',
                            textAlign: 'center',
                            borderWidth: '1px',
                            borderColor: '#d1d5db',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                        >
                        <div style={{ margin: '0 auto', paddingLeft: '32px', paddingRight: '32px' }}>
                            <p style={{ fontSize: '16px', fontWeight: '600', color: '#4b5563' }}>Lesson Bundle</p>
                            <p style={{ display: 'block', fontSize: '16px', marginTop: '15px', fontWeight: '400', color: '#a7a7a7' }}>Book any available timeslots (daytime, evening, weekends)</p>
                            <p
                            style={{
                                marginTop: '24px',
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                            >
                            <span style={{ fontSize: '48px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>
                                $800
                            </span>
                            <span
                                style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#4b5563',
                                letterSpacing: '0.05em',
                                }}
                            >
                                /8 hours
                            </span>
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Pay up front and save $200
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Schedule up to 2 weeks in advance
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} No refunds on unused lessons
                            </p>
                            <a
                            href="https://cal.com/calcprep/8hr-bundle"
                            style={{
                                marginTop: '40px',
                                display: 'block',
                                width: '100%',
                                borderRadius: '8px',
                                backgroundColor: '#6366f1',
                                padding: '8px 12px',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: 'white',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                textDecoration: 'none',
                            }}
                            >
                            Buy Now
                            </a>
                            {/* <p style={{ marginTop: '24px', fontSize: '12px', lineHeight: '1.25', color: '#4b5563' }}>
                            Invoices and receipts available for easy company reimbursement
                            </p> */}
                        </div>
                        </div>
                    </div>

                    <div
                        style={{
                        marginTop: '-8px',
                        padding: '8px',
                        width: '100%',
                        minWidth: '350px',
                        flexShrink: 0,
                        flexBasis: 'calc(45% - 16px)', // Allows divs to be side by side until they wrap
                        boxSizing: 'border-box',
                        }}
                    >
                        <div
                        style={{
                            borderRadius: '16px',
                            backgroundColor: '#f9fafb',
                            paddingTop: '40px',
                            paddingBottom: '40px',
                            textAlign: 'center',
                            borderWidth: '1px',
                            borderColor: '#d1d5db',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                        >
                        <div style={{ margin: '0 auto', paddingLeft: '32px', paddingRight: '32px' }}>
                            <p style={{ fontSize: '16px', fontWeight: '600', color: '#4b5563' }}>Consistent Schedule</p>
                            <p style={{ display: 'block', fontSize: '16px', marginTop: '15px', fontWeight: '400', color: '#a7a7a7' }}>Reserve a weekly available timeslots (daytime, evening, weekends)</p>
                            <p
                            style={{
                                marginTop: '24px',
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                gap: '8px',
                            }}
                            >
                            <span style={{ fontSize: '48px', fontWeight: 'bold', color: '#1f2937', letterSpacing: '-0.025em' }}>
                                $135
                            </span>
                            <span
                                style={{
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#4b5563',
                                letterSpacing: '0.05em',
                                }}
                            >
                                /lesson
                            </span>
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Consistent weekly meeting time
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} Schedule up to 24 weeks in advance
                            </p>
                            <p style={{ marginTop: '12px', fontSize: '14px', lineHeight: '1.5', color: '#4b5563' }}>
                            {'>'} No cancellations allowed
                            </p>
                            <a
                            href="#"
                            style={{
                                marginTop: '40px',
                                display: 'block',
                                width: '100%',
                                borderRadius: '8px',
                                backgroundColor: '#6366f1',
                                padding: '8px 12px',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: 'white',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                textDecoration: 'none',
                            }}
                            >
                            Reserve Now
                            </a>
                            {/* <p style={{ marginTop: '24px', fontSize: '12px', lineHeight: '1.25', color: '#4b5563' }}>
                            Invoices and receipts available for easy company reimbursement
                            </p> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
          </div>
        </div>
      </div>
    );
  }