import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import './components.css'; // Import the CSS file

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <div className="coming-soon-icon">
          <FontAwesomeIcon
            icon={faClock}
            className="icon"
          />
        </div>
        <div className="coming-soon-text">
          <h3 className="coming-soon-title">Content Coming Soon!</h3>
          <div className="coming-soon-message">
            <p>We're working hard to bring you new content. Please check back later.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;