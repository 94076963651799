import * as React from 'react';
// import OpenedSvg from '../images/opened';
// import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';
import { CaretDown, CaretRight } from '@styled-icons/fa-solid';

const TreeNode = ({ className = '', setCollapsed, collapsed, url, title, items, ...rest }) => {
  const isCollapsed = collapsed[url];

  const collapse = (e) => {
    e.preventDefault();
    setCollapsed(url);
  };

  const hasChildren = items.length !== 0;

  let location;

  if (typeof document != 'undefined') {
    location = document.location;
  }
  
  // const active =
  //   location && (location.pathname === url || location.pathname === config.gatsby.pathPrefix + url);
  // const calculatedClassName = `${className} item ${active ? 'active' : ''}`;


  // Determine if this node or any child node is active
  const isActive = location && (location.pathname === url || location.pathname === config.gatsby.pathPrefix + url);
  const isAnyChildActive = items.some(item => 
    location && (location.pathname === item.url || location.pathname === config.gatsby.pathPrefix + item.url)
  );

  // Auto-expand if the current node or any child node is active
  React.useEffect(() => {
    if (isAnyChildActive || isActive) {
      setCollapsed(url, false);
    }
  }, [isActive, isAnyChildActive]);

  const calculatedClassName = `${className} item ${isActive ? 'active' : ''}`;

  return (
    <li className={calculatedClassName}>
      {title && (
        <Link className="dot" to={url}>
          {title}
          {!config.sidebar.frontLine && title && hasChildren ? (
            <button onClick={collapse} aria-label="collapse" className="collapser">
              {!isCollapsed ? (
                <CaretDown size={18} color="black" />
              ) : (
                <CaretRight size={18} color="black" />
              )}
            </button>
          ) : null}
        </Link>
      )}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
