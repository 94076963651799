import Tutoring from "../../../../src/components/mdxComponents/tutoring.js";
import FAQ from "../../../../src/components/mdxComponents/faq.js";
import { Important, IncompleteSection } from "../../../../src/components/mdxComponents/markdownComponents.js";
import * as React from 'react';
export default {
  Tutoring,
  FAQ,
  Important,
  IncompleteSection,
  React
};