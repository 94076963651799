const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://calcprep.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: '',
    // logo: 'https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/brand.svg',
    logoLink: 'https://calcprep.com',
    title:
      '',
      // "<a href='https://calcprep.com'><img class='img-responsive' src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/learn-logo.svg' alt='Learn logo' /></a>",
    githubUrl: '',
    // githubUrl: 'https://github.com/hasura/gatsby-gitbook-boilerplate',
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="https://www.youtube.com/@calcprep" target="_blank" rel="noopener">
		      <div class="youtubeBtn">
            <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2324292e;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M23.498 6.186a2.986 2.986 0 0 0-2.1-2.107C19.368 3.5 12 3.5 12 3.5s-7.368 0-9.398.579a2.986 2.986 0 0 0-2.1 2.107C0 8.197 0 12 0 12s0 3.803.502 5.814a2.986 2.986 0 0 0 2.1 2.107C4.632 20.5 12 20.5 12 20.5s7.368 0 9.398-.579a2.986 2.986 0 0 0 2.1-2.107C24 15.803 24 12 24 12s0-3.803-.502-5.814zM9.75 15.019v-6.038l6.243 3.019-6.243 3.019z'/%3E%3C/svg%3E" alt="YouTube Icon">
		      </div>
		    </a>
		  </li>
			<li>
		    <a href="https://www.instagram.com/calcprep/" target="_blank" rel="noopener">
		      <div class="instagramBtn">
            <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 448 512'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2324292e;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M224.1 141c-63.6 0-115 51.4-115 115s51.4 115 115 115 115-51.4 115-115-51.4-115-115-115zm0 190.2c-41.6 0-75.2-33.6-75.2-75.2s33.6-75.2 75.2-75.2 75.2 33.6 75.2 75.2-33.6 75.2-75.2 75.2zm146.4-194.3c0 14.9-12.1 27-27 27s-27-12.1-27-27 12.1-27 27-27 27 12.1 27 27zm76.1 27.2c-1.7-35.3-9.9-66.7-36.2-93-26.3-26.3-57.7-34.5-93-36.2-36.6-2.1-146.4-2.1-183 0-35.3 1.7-66.7 9.9-93 36.2-26.3 26.3-34.5 57.7-36.2 93-2.1 36.6-2.1 146.4 0 183 1.7 35.3 9.9 66.7 36.2 93 26.3 26.3 57.7 34.5 93 36.2 36.6 2.1 146.4 2.1 183 0 35.3-1.7 66.7-9.9 93-36.2 26.3-26.3 34.5-57.7 36.2-93 2.1-36.6 2.1-146.4 0-183zm-48.5 224c-7.8 19.6-22.9 34.7-42.5 42.5-29.4 11.7-99.2 9-132.5 9s-103.1 2.6-132.5-9c-19.6-7.8-34.7-22.9-42.5-42.5-11.7-29.4-9-99.2-9-132.5s-2.6-103.1 9-132.5c7.8-19.6 22.9-34.7 42.5-42.5 29.4-11.7 99.2-9 132.5-9s103.1-2.6 132.5 9c19.6 7.8 34.7 22.9 42.5 42.5 11.7 29.4 9 99.2 9 132.5s2.7 103.1-9 132.5z'/%3E%3C/svg%3E" alt="Instagram Icon" />
		      </div>
		    </a>
		  </li>
      <li class='reportIssue'>
        <a href="https://forms.gle/Sh6jSNwYK2so9aih9" target="_blank" rel="noopener">
          Report an issue
        </a>
      </li>`,
    links: [
      // { text: 'Courses', link: 'https://calcprep.com/course' },
      // { text: 'Resources', link: 'https://calcprep.com/' },
      { text: 'Tutoring', link: 'https://calcprep.com/tutoring' },
      { text: 'About Me', link: 'https://calcprep.com/about-me' },
      { text: 'Contact', link: 'https://calcprep.com/contact' },
    ],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/precalculus',
      '/preparing-for-calculus-1',
      '/calculus-1',
      '/preparing-for-calculus-2',
      '/calculus-2',
      '/calculus-3',
    ],
    collapsedNav: [
      '/course/precalculus', // add trailing slash if enabled above
      '/course/calculus-1',
      '/course/calculus-2',
      '/course/preparing-for-calculus-2',
      // '/calculus-2/applications-of-integrals',
      // '/calculus-2/integration-techniques',
      '/course/calculus-3',
    ],
    links: [
      // { text: 'CalcPrep - Home Page', link: 'https://hasura.io' },
      // { text: 'Courses', link: 'https://calcprep.com/course' },
      // { text: 'Resources', link: 'https://calcprep.com' },
      { text: 'Tutoring', link: 'https://calcprep.com/tutoring' },
      { text: 'About Me', link: 'https://calcprep.com/about-me' },
      { text: 'Contact', link: 'https://calcprep.com/contact' },
    ],
    frontLine: false,
    ignoreIndex: true,
    title: "<a href='https://calcprep.com'>CalcPrep</a>",
    // "<a href='https://calcprep.com'>home</a><div class='greenCircle'></div><a href='https://calcprep.com'>tutoring</a><div class='greenCircle'></div><a href='https://calcprep.com'>blog</a>",
  },
  siteMetadata: {
    title: 'Online Math Notes | CalcPrep',
    description: 'Online math notes with emphasis on calculus. CalcPrep is a free resource for students to learn and review math concepts.',
    ogImage: null,
    docsLocation: 'https://calcprep.com',
    favicon: 'src/components/images/logo.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
