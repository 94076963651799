import * as React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import './components.css'; // Import the CSS file

const Container = styled.div`
  position: relative;
  width: 100%;
  // 16:9 aspect ratio
  padding-bottom: 56.25%;
`;

const YoutubeEmbed = ({ id, autoplay = false }) => {
  const handleClick = () => {
    window.open('https://www.youtube.com/@calcprep', '_blank');
    };

  return (
    <>
      {id ? (
        <Container className="video-responsive">
          <iframe
            width="750"
            title="Youtube"
            height="422"
            src={`https://youtube.com/embed/${id}${autoplay ? '?autoplay=1' : ''}`}
            frameBorder="0"
            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Container>
      ) : (
        <div className="alert-container" onClick={handleClick}>
          <div className="alert-content">
            <div className="alert-icon">
              <FontAwesomeIcon
                icon={faYoutube}
                className="icon"
              />
            </div>
            <div className="alert-text">
              <h3 className="alert-title">Missing Video</h3>
              <div className="alert-message">
                <p>This video tutorial is still in the works. In the meantime, please use the text version.</p>
                <br></br>
                <p style={{fontStyle : 'italic'}}>For additional content, check out our Youtube channel (click this container). </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default YoutubeEmbed;
